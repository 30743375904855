// Fetch all matchups for the league in the current season
const getAllMatchups = async (leagueId, season, week) => {
    const base_url = 'lm-api-reads.fantasy.espn.com';
    const weekParam = week ? `&scoringPeriodId=${week}` : ``;
    const url = `https://${base_url}/apis/v3/games/ffl/seasons/${season}/segments/0/leagues/${leagueId}?view=mBoxscore&view=mMatchupScore${weekParam}`;
    const options = {
        method: 'GET',
        // Add headers like cookies if needed
    };
    const res = await fetch(url, options);
    const json = await res.json();

    // Return the relevant data or handle errors
    return json.schedule && json.status ? json : null;
};

// Get all current week's games with team details
const getWeekGamesWithTeams = (matchupData, week) => {
    const { schedule, status, teams } = matchupData;
    // const { schedule, status, teams } = matchupData;

    // Map teamId to team details (name, logo)
    const teamMap = {};
    teams.forEach(team => {
        teamMap[team.id] = {
            name: team.name,
            logoUrl: team.logo || '',                   // Assuming `logo` contains the URL
        };
    });

    // console.log(status);
    // if week is passed use that, if not default to current week
    const requestedWeek = week ? week : status.currentMatchupPeriod;
    // Get games for the current week and add team details
    return schedule
        .filter(game => game.matchupPeriodId === requestedWeek)
        .map(game => ({
            ...game,
            home: {
                ...game.home,
                teamName: teamMap[game.home.teamId].name,
                teamLogo: teamMap[game.home.teamId].logoUrl,
            },
            away: {
                ...game.away,
                teamName: teamMap[game.away.teamId].name,
                teamLogo: teamMap[game.away.teamId].logoUrl,
            }
        }));
};

// Filter the matchups to get the specific team's boxscore
const getTeamBoxscore = (currentWeekGames, teamId) => {
    const matchupForTeam = currentWeekGames.find(
        game => game.home.teamId === teamId || game.away.teamId === teamId
    );

    return matchupForTeam || null;
};

// Combined function to get the current boxscore for a specific team
export const getBoxscore = async (leagueId, season, teamId, week) => {
    const matchupData = await getAllMatchups(leagueId, season);
    
    if (matchupData) {
        const currentWeekGames = getWeekGamesWithTeams(matchupData, week);
        const teamMatchup = getTeamBoxscore(currentWeekGames, teamId);

        return {
            team: matchupData.teams.find(team => team.id === teamId),
            boxscore: teamMatchup
        };
    } else {
        return { error: "No matchup data found" };
    }
};

// Example function to get all matchups for the current week (for a scoreboard)
export const getWeekScoreboard = async (leagueId, season, week) => {
    const matchupData = await getAllMatchups(leagueId, season, week);

    if (matchupData) {
        return getWeekGamesWithTeams(matchupData, week);
    } else {
        return { error: "No matchup data found" };
    }
};