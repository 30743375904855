const sum = (items, prop) => {
    return items.reduce((a, b) => {
        return a + b[prop];
    }, 0);
  };

export const totalScore = team => {
    if (team && team.rosterForCurrentScoringPeriod) {
      const starters = team.rosterForCurrentScoringPeriod.entries
                          .filter(entry => entry.lineupSlotId !== 20 && entry.lineupSlotId !== 21)
                          .map(player => player.playerPoolEntry);
      return sum(starters, "appliedStatTotal");
    } else {
      return '...loading'
    }
  };

  export const projectedPoints = (team) => {
    const BENCH_SLOT_IDS = [20, 21];
  
    const hasPlayedGame = (entry) => {
      const stats = entry.playerPoolEntry?.player.stats;
      return stats && stats.some(stat => stat?.variance && Object.keys(stat.variance).length === 0);
    };
  
    const getPlayerPoints = (player) => {
      const stats = player.playerPoolEntry?.player.stats;
      if (!stats || stats.length === 0) return 0;
  
      const gameCompleted = hasPlayedGame(player);
  
      if (gameCompleted) {
        const completedStats = stats.find(stat => stat.variance && Object.keys(stat.variance).length === 0);
        return completedStats?.appliedTotal || 0;
      } else {
        // For games not completed, use the higher appliedTotal
        return Math.max(...stats.map(stat => stat?.appliedTotal || 0));
      }
    };
  
    return team.rosterForCurrentScoringPeriod.entries
      .filter(player => !BENCH_SLOT_IDS.includes(player.lineupSlotId))
      .reduce((sum, player) => sum + getPlayerPoints(player), 0);
  };
  
  


  export const playerIsActiveInRoster = player => {
    return player.lineupSlotId !== 20 && player.lineupSlotId !== 21;
  }

const getActiveRoster = (game, team) => game[team]?.rosterForCurrentScoringPeriod?.entries.filter(playerIsActiveInRoster) || [];

const hasPlayedGame = (entry) => {
  const stats = entry.playerPoolEntry?.player.stats;
return stats && stats.some(stat => stat?.variance && Object.keys(stat.variance).length === 0);
};

const isCurrentlyPlaying = (entry) => {
  const stats = entry.playerPoolEntry?.player.stats;
return stats && stats.some(stat => !stat.hasOwnProperty('variance'));
};

const hasNotPlayedYet = (entry) => {
  const stats = entry.playerPoolEntry?.player.stats;
  return stats && stats.every(stat => stat?.appliedTotal === 0);
};

export const getTeamStats = (game, homeAway) => {
  const activeRoster = getActiveRoster(game, homeAway);
  return {
    playersDone: activeRoster.filter(hasPlayedGame).length,
    playersPlaying: activeRoster.filter(isCurrentlyPlaying).length,
    playersRemaining: activeRoster.filter(hasNotPlayedYet).length
  };
};