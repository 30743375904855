import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Text, Grommet, ResponsiveContext, Avatar, Button, Collapsible } from 'grommet';
import { getWeekScoreboard } from '../espnReader';
import { totalScore, projectedPoints, getTeamStats } from '../util';

// const getActiveRoster = (game, team) => game[team]?.rosterForCurrentScoringPeriod?.entries.filter(playerIsActiveInRoster) || [];

// const hasPlayedGame = (entry) => {
//   const stats = entry.playerPoolEntry?.player.stats;
// return stats && stats.some(stat => stat?.variance && Object.keys(stat.variance).length === 0);
// };

// const isCurrentlyPlaying = (entry) => {
//   const stats = entry.playerPoolEntry?.player.stats;
// return stats && stats.some(stat => !stat.hasOwnProperty('variance'));
// };

// const hasNotPlayedYet = (entry) => {
//   const stats = entry.playerPoolEntry?.player.stats;
//   return stats && stats.every(stat => stat?.appliedTotal === 0);
// };

// const getTeamStats = (game, homeAway) => {
//   const activeRoster = getActiveRoster(game, homeAway);
//   return {
//     playersDone: activeRoster.filter(hasPlayedGame).length,
//     playersPlaying: activeRoster.filter(isCurrentlyPlaying).length,
//     playersRemaining: activeRoster.filter(hasNotPlayedYet).length
//   };
// };

// Grommet Theme (optional, for customizing the look)
const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Arial',
      size: '18px',
      height: '20px',
    },
  },
};

const LeagueScoreboardByScore = ({ leagueId, season }) => {
  const [rawTeams, setRawTeams] = useState([]); // Raw teams data fetched from the API
  const [teamsList, setTeamsList] = useState([]); // Sorted teams list
  const [sortType, setSortType] = useState('total'); // State for sorting by total or projected
  const [showEliminated, setShowEliminated] = useState(false); // State for showing eliminated teams
  const size = useContext(ResponsiveContext); // Get screen size

  useEffect(() => {
    // Fetch the current week's matchups with team info
    const fetchMatchups = async () => {
      try {
        const games = await getWeekScoreboard(leagueId, season);

        // Collect all teams from both home and away sides
        let allTeams = [];
        games.forEach(game => {
          // console.log(game);
          allTeams.push({
            teamId: game.home?.teamId,
            teamName: game.home?.teamName,
            teamLogo: game.home?.teamLogo,
            totalPoints: Math.round(totalScore(game.home) * 100) / 100,
            projectedPoints: Math.round(projectedPoints(game.home) * 100) / 100,
            isEliminated: game.home?.teamName?.startsWith("ELIM"), // Mark eliminated teams based on teamName prefix
            playersDone: getTeamStats(game, 'home').playersDone,
            playersPlaying: getTeamStats(game, 'home').playersPlaying,
            playersRemaining: getTeamStats(game, 'home').playersRemaining
          });
          allTeams.push({
            teamId: game.away?.teamId,
            teamName: game.away?.teamName,
            teamLogo: game.away?.teamLogo,
            totalPoints: Math.round(totalScore(game.away) * 100) / 100,
            projectedPoints: Math.round(projectedPoints(game.away) * 100) / 100,
            isEliminated: game.away?.teamName?.startsWith("ELIM"), // Mark eliminated teams based on teamName prefix
            playersDone: getTeamStats(game, 'away').playersDone,
            playersPlaying: getTeamStats(game, 'away').playersPlaying,
            playersRemaining: getTeamStats(game, 'away').playersRemaining
          });
        });
        setRawTeams(allTeams);
      } catch (error) {
        console.error('Error fetching matchups:', error);
      }
    };
    fetchMatchups();
  }, [leagueId, season]);

  useEffect(() => {
    // Sort teams whenever rawTeams or sortType changes
    const sortedTeams = [...rawTeams].sort((a, b) => {
      if (sortType === 'total') {
        return b.totalPoints - a.totalPoints;
      } else if (sortType === 'projected') {
        return b.projectedPoints - a.projectedPoints;
      }
      return 0;
    });
    setTeamsList(sortedTeams);
  }, [rawTeams, sortType]);

  // Toggle sorting type
  const toggleSortType = () => {
    setSortType(sortType === 'total' ? 'projected' : 'total');
  };

  // Toggle visibility of eliminated teams
  const toggleEliminatedTeams = () => {
    setShowEliminated(!showEliminated);
  };

  // Determine if the layout should be 1-column or 2-column based on screen size
  const isSmallScreen = size === 'small' || size === 'xsmall';

  // Split teams into active and eliminated teams
  const activeTeams = teamsList.filter(team => !team.isEliminated);
  const eliminatedTeams = teamsList.filter(team => team.isEliminated);

  // Calculate the median score
  const medianIndex = Math.floor(activeTeams.length / 2);
  const medianScore = activeTeams[medianIndex-1]?.[sortType === 'total' ? 'totalPoints' : 'projectedPoints'] || 0;

  return (
    <Grommet theme={theme}>
      <Box pad="small">
        {/* Button to toggle sorting */}
        <Box direction="row" justify="end" align="center">
          <Button
            label={`Sort by ${sortType === 'total' ? 'Projected Points' : 'Total Points'}`}
            onClick={toggleSortType}
            size="small"
            margin={{ bottom: 'medium' }}
            alignSelf="end"
          />
        </Box>

        {activeTeams.length > 0 ? (
          <Grid
            rows={isSmallScreen ? 'auto' : 'small'}
            columns={isSmallScreen ? '100%' : ['1/2', '1/2']}
            gap="small"
            responsive
          >
            {activeTeams.map((team, index) => (
              <React.Fragment key={team.teamId}>
                {/* Add Median Score Divider */}
                {index === medianIndex && (
                  <Box fill="horizontal" align="center" pad={{ vertical: 'small' }}>
                    <Text weight="bold" color="brand">
                      Median Score: {medianScore}
                    </Text>
                  </Box>
                )}
                <Box
                  border={{ color: team[sortType === 'total' ? 'totalPoints' : 'projectedPoints'] >= medianScore ? 'brand' : 'status-critical', size: 'small' }}
                  round="small"
                  pad="small"
                  background={team[sortType === 'total' ? 'totalPoints' : 'projectedPoints'] >= medianScore ? 'light-2' : 'light-3'}
                >
                  <Grid
                    rows={['xsmall']}
                    columns={['auto', 'flex']}
                    gap="small"
                    areas={[
                      { name: 'teamInfo', start: [0, 0], end: [1, 0] },
                    ]}
                  >
                    {/* Team Info */}
                    <Box gridArea="teamInfo" direction="row" align="center" gap="small">
                      <Avatar src={team.teamLogo} alt={team.teamName} size='small' />
                      <Box>
                        <Text>{team.teamName}</Text>
                        <Text
                          size="large"
                          weight="bold"
                          color={team[sortType === 'total' ? 'totalPoints' : 'projectedPoints'] >= medianScore ? 'brand' : 'status-critical'}
                        >
                          {team.totalPoints} (proj: {team.projectedPoints})
                        </Text>
                        <Text size="small">
                          Players Done: {team.playersDone} / Playing: {team.playersPlaying} / Remaining: {team.playersRemaining}
                        </Text>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </React.Fragment>
            ))}
          </Grid>
        ) : (
          <Text>Loading teams...</Text>
        )}

        {/* Collapsible component for eliminated teams */}
        <Box margin={{ top: 'medium' }}>
          <Button
            label={`${showEliminated ? 'Hide' : 'Show'} Eliminated Teams`}
            onClick={toggleEliminatedTeams}
            size="small"
          />
          <Collapsible open={showEliminated}>
            <Box pad="small" margin={{ top: 'small' }}>
              {eliminatedTeams.length > 0 ? (
                <Grid
                  rows={isSmallScreen ? 'auto' : 'small'}
                  columns={isSmallScreen ? '100%' : ['1/2', '1/2']}
                  gap="small"
                  responsive
                >
                  {eliminatedTeams.map((team) => (
                    <Box
                      key={team.teamId}
                      border={{ color: 'status-disabled', size: 'small' }}
                      round="small"
                      pad="small"
                      background="light-3"
                    >
                      <Grid
                        rows={['xsmall']}
                        columns={['auto', 'flex']}
                        gap="small"
                        areas={[
                          { name: 'teamInfo', start: [0, 0], end: [1, 0] },
                        ]}
                      >
                        {/* Team Info */}
                        <Box gridArea="teamInfo" direction="row" align="center" gap="small">
                          <Avatar src={team.teamLogo} alt={team.teamName} size='small' />
                          <Box>
                            <Text>{team.teamName}</Text>
                            <Text size="large" weight="bold" color="status-disabled">
                              {team.totalPoints} (proj: {team.projectedPoints})
                            </Text>
                            <Text size="small" color="status-disabled">
                              Players Done: {team.playersDone} / Playing: {team.playersPlaying} / Remaining: {team.playersRemaining}
                            </Text>
                          </Box>
                        </Box>
                      </Grid>
                    </Box>
                  ))}
                </Grid>
              ) : (
                <Text>No eliminated teams</Text>
              )}
            </Box>
          </Collapsible>
        </Box>
      </Box>
    </Grommet>
  );
};

export default LeagueScoreboardByScore;

