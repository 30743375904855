import React, { useState } from 'react';
import { Box, Button, Grommet, Text } from 'grommet';
import LeagueScoreboard from './LeagueScoreboard'; // Matchup-based scoreboard
import LeagueScoreboardByScore from './LeagueHighScoreBoard';

// Grommet Theme (optional)
const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Arial',
      size: '18px',
      height: '20px',
    },
  },
};

const ToggleableLeagueScoreboard = ({ leagueId, season }) => {
  // State to toggle between scoreboards
  const [showOverallScore, setShowOverallScore] = useState(true);

  // Toggle function
  const toggleScoreboard = () => {
    setShowOverallScore(prevState => !prevState);
  };

  return (
    <Grommet theme={theme}>
      <Box pad="medium">
        <Text size="large" weight="bold" margin={{ bottom: 'small' }}>
          {showOverallScore ? 'Overall Score Leaderboard' : 'Matchup Scoreboard'}
        </Text>

        {/* Toggle Button */}
        <Button
          label={showOverallScore ? 'Switch to Matchups' : 'Switch to Overall Scores'}
          onClick={toggleScoreboard}
          margin={{ bottom: 'medium' }}
        />

        {/* Conditionally Render the Scoreboards */}
        {showOverallScore ? (
          <LeagueScoreboardByScore leagueId={leagueId} season={season} />
        ) : (
          <LeagueScoreboard leagueId={leagueId} season={season} />
        )}
      </Box>
    </Grommet>
  );
};

export default ToggleableLeagueScoreboard;