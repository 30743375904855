// import './App.css';
import { 
  Accordion, 
  AccordionPanel, 
  Box,
  Main, 
  Page, 
  PageContent,
  Card,
  CardBody,
  Markdown,
  Header,
  Text
 } from 'grommet';

import LeagueCard from './LeagueCard';
import ToggleableLeagueScoreboard from './LeagueScoreboard/LeagueScoreboardToggle';

const leagues = [
  { name: "Josh",
    id: '1661561268',
    team: 1
  },
  {
    name: 'Luke',
    id: '286565',
    team: 7
  },
  {
    name: 'Boozie',
    id: '319397',
    team: 11
  }
];

const AppHeader = () => (
  <Header background="brand" pad="small">
    <Text size="large" weight="bold" color="light-1">Fantasy Pals</Text>
  </Header>
);

const leaguePanel = league => {
  return (
    <AccordionPanel label={league.name}>
      <Box pad="medium" margin="small">
        <LeagueCard ownerName={league.name} leagueID={league.id} teamID={league.team}/>
      </Box> 
    </AccordionPanel>
  )
}

function App() {
  // const size = React.useContext(ResponsiveContext)

  return (
    <div className="App">
      {/* TODO - add nav bar for each  team */}
      {/* TODO - add "add league" to navbar */}
      
      <Main>
      <AppHeader />
        {/* <Text>Size is: {size}</Text> */}
        <Page kind="narrow" pad="medium">
            <PageContent>
            
              <ToggleableLeagueScoreboard leagueId={175750856} season={2024}/>
              <Card>
                <CardBody pad='medium'>
                  <Markdown>More information about the Fantasy Pals elimintation league can be found on the [FPEL Notion page](https://bit.ly/fantasypals)</Markdown>
                </CardBody>
              </Card>
              <Accordion multiple={true} pad="medium">
                {leagues.map(league => leaguePanel(league))}
              </Accordion>
          </PageContent>
        </Page>
      </Main>
    </div>
  );
}

export default App;
